import React from "react";
import { DiscussionEmbed } from "disqus-react";

const Disqus = (props) => {
  const disqusConfig = {
    identifier: props.slug,
    title: props.slug,
    onReady: () => {
      props.onReady();
    },
    onInit: () => {
    },
  };

  return process.env.GATSBY_DISQUS_SHORTNAME ? (
    <DiscussionEmbed
      shortname={process.env.GATSBY_DISQUS_SHORTNAME}
      config={disqusConfig}
    />
  ) : (
    // <pre>{JSON.stringify(disqusConfig, null, 2)}</pre>
    <></>
  );
};

export default Disqus;
